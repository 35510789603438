import React, {useState, Component} from 'react'
import withContext from "../withContext";
import Row from 'react-bootstrap/Row';
import { loadStripe } from "@stripe/stripe-js";
import Formsincheckout from './formsincheckout';

class Precheckout extends React.Component {
  
  render(){
    


  return (
    <>
    <Formsincheckout />
    </>
  );
  }
};

export default withContext(Precheckout);