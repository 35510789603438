import React, {useState} from 'react'
import withContext from "../withContext";
import CartItem from "./CartItem";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { useHistory } from "react-router-dom";
import PrivacyPolicy from './Privacy Policy for yekaterinazhmud.pdf'
import Row from 'react-bootstrap/Row';
import { loadStripe } from "@stripe/stripe-js";


class Formsincheckout extends React.Component {
    constructor (props) {
    super(props);

    }


    componentDidMount(){
        window.scrollTo(0, 250);
      }
    onSubmit(){
    }


        render() {
            const accept = this.props.context.acceptForms;
            let email = "";
            let firstName = "";
            let lastName = "";
            let region = "";
            let country = "";
            let City = "";
            let Street = "";
            let HouseN = "";
            let Zip = "";

            {this.props.context.buyer.email ? (email = this.props.context.buyer.email) : (email = "")}
            {this.props.context.buyer.name ? (firstName = this.props.context.buyer.name) : (firstName = "")}
            {this.props.context.buyer.lastName ? (lastName = this.props.context.buyer.lastName) : (lastName = "")}
            {this.props.context.buyer.country ? (country = this.props.context.buyer.country) : (country = "")}
            {this.props.context.buyer.region ? (region = this.props.context.buyer.region) : (region = "")}
            {this.props.context.buyer.city ? (City = this.props.context.buyer.city) : (City = "")}
            {this.props.context.buyer.street ? (Street = this.props.context.buyer.street) : (Street = "")}
            {this.props.context.buyer.houseN ? (HouseN = this.props.context.buyer.houseN) : (HouseN = "")}
            {this.props.context.buyer.index ? (Zip = this.props.context.buyer.index) : (Zip = "")}

            let DisabledValue = false;

            function FormValids () {

                const [validated, setValidated] = useState(false);
                const [submitedemail, setSubmitedemail] = useState(email);
                const [submitedFirstName, setSubmitedFirstName] = useState(firstName);
                const [submitedLastName, setSubmitedLastName] = useState(lastName);
                const [submitedCountry, setCountry] = useState(country);
                const [submitedRegion, setRegion] = useState(region);
                const [submitedCity, setSubmitedCity] = useState(City);
                const [submitedStreet, setSubmitedStreet] = useState(Street);
                const [submitedHouseN, setSubmitedHouseN] = useState(HouseN);
                const [submitedZip, setSubmitedZip] = useState(Zip);



                let history = useHistory();
                DisabledValue = DisabledValue;

                const handleEmailChange = (val) => {
                    setSubmitedemail(val.target.value)
                }
                
                const handleNameChange = (val) => {
                    setSubmitedFirstName(val.target.value)
                }

                const handleLastNameChange = (val) => {
                    setSubmitedLastName(val.target.value)
                }

                const handleCityChange = (val) => {
                    setSubmitedCity(val.target.value)
                }
                const handleStreetChange = (val) => {
                    setSubmitedStreet(val.target.value)
                }
                const handleHouseNChange = (val) => {
                    setSubmitedHouseN(val.target.value)
                }
                const handleZipChange = (val) => {
                    setSubmitedZip(val.target.value)
                }

                const selectCountry = (val) => {
                    setCountry(val);
                    setRegion("")
                }

                const selectRegion = (val) => {
                    setRegion(val)
                }


                function HandleSubmit (event) {
                const form = event.currentTarget;


                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                } 

                if (form.checkValidity() === true) {

                    if (submitedCountry === "" || submitedRegion === ""){
                        event.preventDefault();
                        event.stopPropagation();
                            if (country === ""){
                                document.getElementsByClassName('RegionDropDown')[0].style.borderColor = "#ff0040";
                                document.getElementsByClassName('CountryDropdown')[0].style.borderColor = "#ff0040"
                                } else {
                                document.getElementsByClassName('RegionDropDown')[0].style.borderColor = "#ff0040"
                            }

                    } else {
                        document.getElementsByClassName('RegionDropDown')[0].style.borderColor = "#ced4da";
                        document.getElementsByClassName('CountryDropdown')[0].style.borderColor = "#ced4da";
                        accept(form);
                        history.push('/FinalSummary');
                    }

                    
                }
                setValidated(true)

                }

        
    
                return (
            
                <Row className="mt-3 justify-content-md-center" >
                    <div className="hero-body container BorderBottomItemCart p-0 m-0">
                        <h4 className="title">Please enter your contact and delivery details</h4>
                    </div>
                    <Col className="pt-5" >

                    <Form noValidate validated={validated} onSubmit={HandleSubmit}  >
                    
                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="validationCustom05">
                            <Form.Control required type="email" placeholder="E-mail address" value ={submitedemail}                           
                            onChange={handleEmailChange} 
/>
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                            <Form.Control
                            required
                            type="text"
                            placeholder="First Name"
                            value ={submitedFirstName}
                            onChange={handleNameChange} 

                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                            <Form.Control
                            required
                            type="text"
                            placeholder="Last Name"
                            value ={submitedLastName}
                            onChange={handleLastNameChange} 

                            
                            />
                        </Form.Group>
                    </Form.Row>

                        <Form.Row className="mt-2" md="8">
                            <Form.Group  as={Col} controlId="validationCustom00">
                                <CountryDropdown
                         
                                value={submitedCountry}
                                onChange={(val) => selectCountry(val)} 
                                // showDefaultOption={true}
                                className="CountryDropdown"/>

                                <RegionDropdown
                                country={submitedCountry}
                                value={submitedRegion}
                                onChange={(val) => selectRegion(val)} 
                                className="RegionDropDown ml-2"/>

                            </Form.Group>
                        </Form.Row>
                        
                    <fieldset disabled={DisabledValue}>

                        <Form.Row className="mt-2">
                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                            <Form.Control
                            required
                            type="text"
                            placeholder="City"
                            value ={submitedCity}
                            onChange={handleCityChange} 
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="validationCustom03">
                            <Form.Control
                            required
                            type="text"
                            placeholder="Street name"
                            value ={submitedStreet}
                            onChange={handleStreetChange} 
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="2" controlId="validationCustom03">
                            <Form.Control
                            required
                            type="text"
                            placeholder="House №/ Addition"
                            value ={submitedHouseN}
                            onChange={handleHouseNChange} 
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="2" controlId="validationCustom04">
                            <Form.Control
                            required
                            type="text"
                            placeholder="Postal code"
                            value ={submitedZip}
                            onChange={handleZipChange} 
                            />
                        </Form.Group>
                        </Form.Row>
                        

                            
                        <Form.Group>
                            <Form.Check  type='checkbox' id={`id`}>
                            <Form.Check.Input required type='checkbox' />
                            <Form.Check.Label>I want my order to be sent to this address and I agree to {<a href={PrivacyPolicy} target="_blank">the privacy policy</a>}</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">You should agree with the privacy policy</Form.Control.Feedback>                 
                            </Form.Check>
                        </Form.Group>
                    </fieldset>
                        <Button variant="outline-secondary" onClick={() => history.goBack()}>Back</Button>

                        <Button variant="outline-success" type="submit" className="ml-2">Proceed to checkout</Button>
                    </Form>
                    </Col>

                    
                </Row>
                )
            }

            return (
                <>


                    <FormValids 
                    onSubmit={this.onSubmit}
                    />

                </>
            )
        }   
}
export default withContext(Formsincheckout)