import React, { Component } from "react";
import withContext from "../withContext";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import axios from 'axios';

class PostStripe extends Component {

  componentDidMount(){
    let web = window.location.href;
    let index = web.indexOf('success=true');
    let clean = this.props.context.clearCart;
    let clearBuyerData = this.props.context.clearBuyerData;

    if (index != -1) {
        axios.get('/managingsuccess');
        clean();
        clearBuyerData();
      }
  }

  render() {
    let web = window.location.href;
    let index = web.indexOf('success=true');

  return (
    <Container className="mt-5">
    {index != -1 ? 
        (<Col md={{ span: 8, offset: 2 }} id="firsttext">
          Thank you for your purchase! Your order will be sent within 3 working days. 
          <br/>Best regards, Yekaterina!
        </Col>) : 
        (<Col md={{ span: 8, offset: 2 }} id="firsttext">
          :O Something gone wrong! <br/> Please, try again or contact me for futher support
        </Col>)
    }
        
    </Container>
  );
}};

export default withContext(PostStripe);