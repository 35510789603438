import React, {Component} from 'react'
import Row from 'react-bootstrap/Row';
import Carousel from 'react-bootstrap/Carousel';

import cross from './close.png'
import { Link } from 'react-router-dom'
import withContext from "../../withContext"; 

class Galleryitem extends React.Component{
    constructor(props){
    super(props)
    }

    componentDidMount(){
        this.props.context.setindicator();
        document.body.style.overflow = "hidden";
  
    }


    render(){
    let galleryprops = this.props.location.about;

    if (galleryprops !== undefined){
        localStorage.setItem("lastusedgalleryprops", JSON.stringify(galleryprops));
    } else {
        galleryprops = JSON.parse(localStorage.getItem("lastusedgalleryprops"));
    }


    function handleclick() {
        document.body.style.overflow = "visible";
    };

        return (
            <>
            <Row className="row justify-content-center">
                    {galleryprops.items.map((item)=>{
                        return (
                                <div className="row w-25 m-3 card">
                                    <div className="card-img">
                                        <img className="card-img" src={item} alt="Card image" />
                                    </div>
                                </div>
                        )
                    })
                    }
            </Row>

            <Carousel controls={true} indicators={false} interval={null} defaultActiveIndex={galleryprops.index} className="GalleryBackground">
                {galleryprops.items.map((img)=>{
                    return (
                            <Carousel.Item className="GalleryItem">
                                <div className="glry">
                                
                                <Link onClick={handleclick} className="cancelcross" to="/Gallery"> 
                                        <img src={cross} className="w-100 h-100"></img>
                                    {/* <h4>close</h4> */}
                                </Link>

                                    <img className="glryimg"
                                    src={img}
                                    alt="First slide"
                                    >
                                    </img>


                                    <div className="glrytext">


                                        <h5>{galleryprops.items.indexOf(img)+1} of {galleryprops.items.length}</h5>
                                    </div>

                                </div>

                            </Carousel.Item>
                                                       


                    )
                })
                }
 
            </Carousel>
            

            </>

        )
    }
}
export default withContext(Galleryitem);
    