import React, { Component } from "react";
import withContext from "../../withContext";
import axios from 'axios';
import Item from './Item'
import { Link } from 'react-router-dom'


class ProductItem extends React.Component {
  componentDidMount(){
    this.props.context.refresh();
  }
  async handleclick (id) {

      await axios.delete(`/deleteproduct/${id}`)
      .then(res => console.log(res.data))
    }
  render() {
    const { product, user } = this.props;
  return (
    <div className="mx-auto productitem mt-5 card bg-dark text-white" style={{order : product.order}} >
        <div className="media">

              <div className="card-img">
                <img
                  className="card-img"
                  src={product.URLp}
                  alt={product.shortDesc}
                />
                <Link className="newover"  to={{pathname:`/Painting/${product.id}`, aboutProps: {product}}}>
                  <div className="imgtext">
                    <div className="imgtexttop">
                    <h3>{product.name}</h3>
                    <h5>{product.shortDesc}</h5>
                    <h5>{product.price}€</h5>
                    </div>
                    <div className="imgtextbot">

                    <h4>Click to see the product page</h4>  
                    </div>   
                  </div>
                </Link>
              </div>
          <div className="media-content">

            <div className="is-clearfix">
                {user && user.accessLevel < 1 && (
                  <button className="button" onClick={()=>{this.handleclick(product.id)}}>
                    Delete product
                  </button>
               )}
            </div>
          </div>
        </div>
    </div>
  );
}};

export default withContext(ProductItem);