import React from 'react'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import withContext from "../../withContext";

import eyehead from '../pages/IMG_0187.JPG';
import oldpaintings from '../pages/IMG_6087.webp'
// import bear from '../pages/IMG_5818.webp'
import bear from '../pages/V6QVC6e.jpeg'
import octopus from '../pages/Relaxing_bath.webp'





class HomePage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    }
    this.onScroll = this.onScroll.bind(this);


  }

  onScroll(){

    if (window.scrollY*1.2 > document.getElementsByClassName('firstzhmudphoto')[1].offsetHeight*1.3){
      document.getElementsByClassName('firstzhmudphoto')[1].classList.add("aloha");

    }
    if (window.scrollY > document.getElementsByClassName('firstzhmudphoto')[2].offsetHeight*1.3){
      document.getElementsByClassName('firstzhmudphoto')[2].classList.add("aloha");
    }
    if (window.scrollY > document.getElementById('PainedKate').offsetHeight*4.6){
      this.setState({ transform: `translate(0, 0) rotate(0)` });
    }

  }
  
  componentDidMount() {
    this.props.context.refresh();

    if (window.screen.width >= 768){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
    window.addEventListener("scroll", this.onScroll);
    setTimeout(() => {
      if (document.getElementsByClassName('firstzhmudphoto')[0]) {
      document.getElementsByClassName('firstzhmudphoto')[0].classList.add("aloha")}

    }, 1);
  }}

  componentWillUnmount(){
    window.removeEventListener("scroll", this.onScroll);
  }

  render(){

    let stylednormal = { transform: this.state.transform }

    if (window.screen.width >= 768){

    return (

         
    <Container className="mb-4 mt-5"  >
      
       
       <Row className="mt-5 justify-content-between" className="firstzhmudphoto" >
            <Col id="firsttext" className="" >
              <h2> Hi, I'm Kate, and I see my mission in making people's homes brighter with my art. </h2><br/>
              <h2> My paintings, drawings and prints live in 17 cities of 8 countries from Siberia to California, and I don't plan to stop! </h2><br/>
              <h2> My art made happy so many people (I sold over 50 pieces of art), and it makes me even happier! ✨ </h2><br/>

            </Col>

            <Col md={{ span: 6 }} id="HomeZhmudPhoto " className="pl-0 pr-0 ">
                <img
                    className="w-100"
                    src={eyehead}
                    alt="oiks"
                />
            </Col>
        </Row>

        <Row className=" justify-content-between firstzhmudphoto">
            
            <Col md={{ span: 6 }} id="HomeZhmudPhoto" className="pl-0 pr-0">
                <img
                    className="w-100"
                    src={octopus}
                    alt="oiks"
                />
            </Col>

            <Col md={{ span: 6 }} id="firsttext" className="" >
              <h2> I'm never constrained with mediums or materials and I believe in ZERO boundaries when it comes to art.</h2><br/>
            </Col>
            

        </Row>

        <Row className=" justify-content-between firstzhmudphoto">
              


              <Col md={{ span: 6 }} id="firsttext" className="" >
                <h2> Bright colors, surrealism, irony, absurd, wrap it all! Sprinkle with rhymes and add something unexpected, and we're done! That's the DNA of my art.  </h2><br/>
                <br/><h2>I really love animals and I can't express my love more than painting them all the time!</h2>
                <br/><h2>Through my art I hope to show all the variety of different world’s aspects, but also a plenty of other worlds that only exist in our minds.</h2>
                <br/><h2>I hope you enjoy it!</h2>   <br/>
              </Col>
              <Col md={{ span: 6 }} id="HomeZhmudPhoto" className="pl-0 pr-0">
                  <img
                      className="w-100"
                      src={bear}
                      alt="oiks"
                  />
              </Col>
        </Row>  


          <div className="pieces" id="PainedKate" >

          <div className="frame ">
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            <div class="slice" style={stylednormal}></div>
            </div></div>
            
                   
    </Container>
  )} else {

    return (
  <Container className="mb-4 mt-4"  >
      
       
       <Row className="mt-5 justify-content-between" className="firstzhmudphoto aloha" >
            <Col id="firsttext" className="" >
              <h2> Hi, I'm Kate, my mission is making people's homes brighter with my art ✨</h2><br/>

            </Col>

            <Col md={{ span: 6 }} id="HomeZhmudPhoto " className="pl-0 pr-0 ">
                <img
                    className="w-100"
                    src={eyehead}
                    alt="oiks"
                />
            </Col>
        </Row>

        <Row className=" justify-content-between firstzhmudphoto aloha">
            


            <Col md={{ span: 6 }} id="firsttext" className="" >
              <h2> I'm never constrained with mediums or materials and I believe in ZERO boundaries when it comes to art.</h2><br/>
            </Col>
            <Col md={{ span: 6 }} id="HomeZhmudPhoto" className="pl-0 pr-0">
                <img
                    className="w-100"
                    src={octopus}
                    alt="oiks"
                />
            </Col>

        </Row>

        <Row className=" justify-content-between firstzhmudphoto aloha">
              



              <Col md={{ span: 6 }} id="firsttext" className="" >
                <br/><h2>I really love animals and I can't express my love more than painting them all the time!</h2>
              </Col>

              <Col md={{ span: 6 }} id="HomeZhmudPhoto" className="pl-0 pr-0">
                  <img
                      className="w-100"
                      src={bear}
                      alt="oiks"
                  />
              </Col>
              <Col md={{ span: 6 }} id="firsttext" className="" >

              <br/><h2>I hope you enjoy it! </h2>
              </Col>

        </Row>  


            
                   
    </Container>
      )
  }
}}
export default withContext(HomePage)
