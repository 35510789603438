import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PrivacyPolicy from './Privacy Policy for yekaterinazhmud.pdf';
import TermsOfService from './TERMS OF SERVICE.pdf'


import insta from "./rnb-ig.webp"
import etsy from "./rnb-et.webp"
import fb from "./rnb-fb.webp"
import cata from "./rnb-cw.webp"
import reddit from "./reddit.webp"




const Footer = () => {
  if (window.screen.width >= 769){
  return (
      <div className="font-small pt-4 mt-4 bg-white">

       

        <Row className="BorderTopFooter mt-5 mb-5">
          <Col className="mt-3">
            <Row>
              <h2>Yekaterina Zhmud </h2>
            </Row>
            <Row>
              <h3>Chamber of commerce Amsterdam, The Netherlands<br />
              Chamber of commerce (kvk): 81649886</h3>
            </Row>
          </Col>

          <Col className="mt-3">
            <Row>
              <a href={TermsOfService} target="_blank" className=""> <h2>Terms of service</h2></a>
            </Row>
            <Row>
              <a href={PrivacyPolicy} target="_blank" className=""><h2>Privacy Policy</h2></a>
            </Row>
          </Col>

          <Col>
            <Row className="footerIcons">
              <div className="icon-holder text-center  ">
                <a className="one" href="https://www.instagram.com/yekaterina_zhmud/">
                  <img className="icon " src={insta} alt="picture"/>
                </a>
              </div>

              <div className="icon-holder  text-center  ">
                <a className="one" href="https://www.etsy.com/shop/yekaterinazhmud"><img className="icon " src={etsy} alt="picture"/>
              </a>
              </div>
              <div className="icon-holder text-center  ">
                <a className="one" href="https://www.facebook.com/yekaterinazhmud.artist"><img className="icon" src={fb} alt="picture"/>
              </a>
              </div>

              <div className="icon-holder text-center ">
                <a className="one" href="https://www.reddit.com/user/yekaterina_zhmud"><img className="icon " src={reddit} alt="picture"/>
              </a>
              </div>
          </Row>
          </Col>

        </Row>

        {/* <div className="footer-copyright text-center py-3">


      </div> */}
      </div>

  );
} else {
  return (

    <div className="font-small pt-4 mt-4 bg-white">

<Col>
        <Row className="footerIcons">
          <div className="icon-holder text-center  ">
            <a className="one" href="https://www.instagram.com/yekaterina_zhmud/">
              <img className="icon " src={insta} alt="picture"/>
            </a>
          </div>

          <div className="icon-holder  text-center  ">
            <a className="one" href="https://www.etsy.com/shop/yekaterinazhmud"><img className="icon " src={etsy} alt="picture"/>
          </a>
          </div>
          <div className="icon-holder text-center  ">
            <a className="one" href="https://www.facebook.com/yekaterinazhmud.artist"><img className="icon" src={fb} alt="picture"/>
          </a>
          </div>

          <div className="icon-holder text-center ">
            <a className="one" href="https://www.reddit.com/user/yekaterina_zhmud"><img className="icon " src={reddit} alt="picture"/>
          </a>
          </div>
      </Row>
      </Col>

    <Row className="BorderTopFooter mt-5 mb-5">
      <Col className="mt-3">
        <Row>
          <h2>Yekaterina Zhmud </h2>
        </Row>
        <Row>
          <h3>Chamber of commerce Amsterdam, The Netherlands<br />
          Chamber of commerce (kvk): 81649886</h3>
        </Row>
      </Col>

      <Col className="mt-3">
        <Row>
          <a href={TermsOfService} target="_blank" className=""> <h2>Terms of service</h2></a>
        </Row>
        <Row>
          <a href={PrivacyPolicy} target="_blank" className=""><h2>Privacy Policy</h2></a>
        </Row>
      </Col>

      

    </Row>

    {/* <div className="footer-copyright text-center py-3">


  </div> */}
  </div>

  )
}}


export default Footer;