import React, { Component } from 'react';
import { Route, Switch, Link, BrowserRouter, HashRouter} from 'react-router-dom';
import Home from './pages/HomePage';
import Shop from './pages/Shop'
import Item from './pages/Item';
import Gallery from './pages/Gallery';
import GalleryItem from './pages/GalleryItem';
import ContactForm from './pages/ContactForm';
import Header from '../mains/header/header'
import Footer from '../mains/footer/footer'
import AddProduct from '../components/AddProduct';
import Cart from '../components/Cart';
import Login from '../components/Login';
import FinalSummary from '../components/FinalSummary';
import Context from "../Context";
import Box from "./pages/Box";

import CookieConsent from "react-cookie-consent";



import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Precheckout from '../components/Precheckout';
import PostStripe from '../components/PostStripe';




// CLEAN THIS SHITSHEED LIKE ASAP
class Mains extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          user: null,
          cart: {},
          buyer: {country: '', region: '', city: '', index: '', street: '', houseN: '', name: '', lastName: '', email: ''},
          promocode: {},
          products: [{}],
          freezed: false,
          apiResponse: ""
        };
        this.setindicator = this.setindicator.bind(this);
        this.selectCountry = this.selectCountry.bind(this);
        this.selectEmail = this.selectEmail.bind(this);
        this.selectRegion = this.selectRegion.bind(this);
        this.acceptForms = this.acceptForms.bind(this);
        this.clearCart = this.clearCart.bind(this);
        this.clearBuyerData = this.clearBuyerData.bind(this);
        this.refresh = this.refresh.bind(this);



        this.routerRef = React.createRef();

      
      }



      setindicator(){
        if (this.state.freezed === false){
        this.setState({freezed: true});
        } else {
          this.setState({freezed: false});
        }
      }

      selectEmail (val) {
        let buyer = this.state.buyer;
        buyer.email = val;
        this.setState({ buyer });
      }
    
      selectCountry (val) {
        let buyer = this.state.buyer;
        buyer.country = val;
        this.setState({ buyer });
      }
    
      selectRegion (val) {
        let buyer = this.state.buyer;
        buyer.region = val;
        this.setState({ buyer });
      }

      acceptForms (val) {
        let buyer = this.state.buyer;
          buyer.email = val[0].value;
          buyer.name = val[1].value;
          buyer.lastName = val[2].value;

          buyer.country = val[3].value;
          buyer.region = val[4].value;



          buyer.city = val[6].value;
          buyer.street = val[7].value;
          buyer.houseN = val[8].value;
          buyer.index = val[9].value;

          this.setState({ buyer });
          localStorage.setItem("buyer", JSON.stringify(buyer));

      }

      login = async (email, password) => {
        const res = await axios.post(
          '/login',
          { email, password }
        ).catch((res) => {
          return { status: 401, message: 'Unauthorized' }
        })
      
        if(res.status === 200) {
          const user = res.data;  
          this.setState({ user });
          localStorage.setItem("user", JSON.stringify(user));
          return true;
        } else {
          return false;
        }
      }

      CheckPromocode = async (promocode) => {
        const res = await axios.post(
          '/promocodes',
          { promocode }
        ).catch((res) => {
          return { status: 401, message: 'Something went wrong' }
        })
      
        if(res.status === 200) {
          if (res.data.propercode){
            const data = res.data.propercode;  

            let promocode = { name: data.Promocode, discount: data.Discount, ProductID: data.ProductID }
            this.setState({ promocode });
            localStorage.setItem("promocode", JSON.stringify(promocode));
            this.clearPromocode(1000*60*15);
            return true;
          } else {
            let promocode = { message: res.data };
            localStorage.setItem("promocode", JSON.stringify(promocode));
            this.setState({ promocode });
            this.clearPromocode(1000*3);
          }
          
        } 
      }

      clearPromocode = (time) => {
        setTimeout(() => {
          let promocode = {};
          localStorage.removeItem("promocode");
          this.setState({ promocode });
        }, time);
      };

      logout = e => {
        e.preventDefault();
        this.setState({ user: null });
        localStorage.removeItem("user");
      };

      async refresh (){
        const products = await axios.get('/products');
        if (products){
          this.setState({ products: products.data });
        } 
      }

      addProduct = (product, callback) => {
        let products = this.state.products.slice();
        products.push(product);
        this.setState({ products }, () => callback && callback());
      };

      addToCart = cartItem => {
        let cart = this.state.cart;
        if (cart[cartItem.id]) {
          cart[cartItem.id].amount += cartItem.amount;
        } else {
          cart[cartItem.id] = cartItem;
        }
        if (cart[cartItem.id].amount > cart[cartItem.id].product.stock) {
          cart[cartItem.id].amount = cart[cartItem.id].product.stock;
        }
        localStorage.setItem("cart", JSON.stringify(cart));
        this.setState({ cart });
      };

      AmountCount = () => {
      
        const cart = this.state.cart;
        const products = this.state.products.map(p => {
          if (cart[p.name]) {
            p.stock = p.stock - cart[p.name].amount;
      
            axios.put(
              `/amproducts/${p.id}`,
              { ...p },
            )
          }
          return p;
        });
      
        this.setState({ products });
      };

      removeFromCart = cartItemId => {
        let cart = this.state.cart;
        delete cart[cartItemId];
        localStorage.setItem("cart", JSON.stringify(cart));
        this.setState({ cart });
      };
      
      clearCart = () => {
        let cart = {};
        localStorage.removeItem("cart");
        this.setState({ cart });
      };

      clearBuyerData = () => {
        let buyer = {country: '', region: '', city: '', index: '', street: '', houseN: '', name: '', lastName: '', email: ''};
        localStorage.removeItem("buyer");
        this.setState({  buyer });
      };
      
      async componentDidMount() {
        let user = localStorage.getItem("user");
        let cart = localStorage.getItem("cart");
        let buyer = localStorage.getItem("buyer")
        let promocode = localStorage.getItem("promocode");

        const products = await axios.get('/products');
        if (products){
          user = user ? JSON.parse(user) : null;
          cart = cart ? JSON.parse(cart) : {};
          buyer = buyer ? JSON.parse(buyer) : {country: '', region: '', city: '', index: '', street: '', houseN: '', name: '', lastName: '', email: ''};
          promocode = promocode ? JSON.parse(promocode): {};
          {promocode.message ? (this.clearPromocode(1000*5)) : (this.CheckPromocode(promocode.name))}


          this.setState({ user, cart, buyer, products: products.data, promocode });
        } 
      }


    render (){
        return (
            <Context.Provider
            value={{
              ...this.state,
              refresh: this.refresh,
              removeFromCart: this.removeFromCart,
              addToCart: this.addToCart,
              login: this.login,
              addProduct: this.addProduct,
              clearCart: this.clearCart,
              clearBuyerData: this.clearBuyerData,
              AmountCount: this.AmountCount,
              setindicator: this.setindicator,
              selectCountry: this.selectCountry,
              selectRegion: this.selectRegion,
              acceptForms: this.acceptForms,
              CheckPromocode: this.CheckPromocode,
              clearPromocode: this.clearPromocode,
              selectEmail: this.selectEmail
            }}
            >
            
              <BrowserRouter ref={this.routerRef}>

                {window.location.href.toUpperCase().indexOf('/BOXES') === -1 ? <Header key={window.location.pathname}/> : ''} 

                  <div className="navbar-menu">
                      {this.state.user && this.state.user.accessLevel < 1 && (
                        <Link to="/add-product" className="nav-link">
                          Add Product
                        </Link>
                      )}
                      {this.state.user && (
                        <Link to="/" className="nav-link" onClick={this.logout} >
                          Logout
                        </Link>
                      )}
                  </div>

                    {/* <div id="SuccessResponseForms">
                      <h2>FORMS SUBMITED</h2>
                      <button onClick={()=>{document.getElementById('SuccessResponseForms').style.display = "none"}}><Link to="/ContactForm">OKAY</Link></button>
                    </div> */}

                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path ="/Shop" component={Shop} />
                    <Route path ="/Boxes" component={Box}/>
                    <Route path ="/Painting" component={Item}/>
                    <Route path ="/Gallery" component={Gallery} />
                    <Route path ="/GalleryItem" component={GalleryItem}/>
                    <Route path ="/ContactForm" component={ContactForm}/>
                    <Route path="/login" component={Login} />
                    <Route path="/cart" component={Cart}/>
                    <Route path="/add-product" component={AddProduct} />
                    {/* <Route path="/products" component={ProductList} /> */}
                    <Route exact path="/precheckout" component={Precheckout} />
                    <Route path="/PostStripe" component={PostStripe} />
                    <Route path="/FinalSummary" component={FinalSummary} />
                </Switch>
                
                <Footer />
                <CookieConsent   
                  style={{ background: "#2B373B" }}
                  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}>
                  Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies. By using this website you agree to our use of cookies.
                </CookieConsent>

              </BrowserRouter>

            </Context.Provider>

        )
    }}
  
export default Mains;
