import React from 'react'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';


import withContext from "../../withContext";
import ProductItem from "./ProductItem";


class Shop extends React.Component {
    
    constructor(props){
        super(props);
    }
    componentDidMount() {
        this.props.context.refresh();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

      }
    render() {

        const { products } = this.props.context;
        const { user } = this.props.context;


        return (
            <Tab.Container defaultActiveKey="first">
                        <Nav justify="true" variant="pills" className="w-100 flex-row">
                        <Nav.Item >
                            <Nav.Link eventKey="first" className="nav-link-shop">PAINTINGS</Nav.Link>
                        </Nav.Item>
                        <Nav.Item >
                            <Nav.Link eventKey="second" className="nav-link-shop">PRINTS</Nav.Link>
                        </Nav.Item>
                        <Nav.Item >
                            <Nav.Link eventKey="three" className="nav-link-shop">CRAFTS</Nav.Link>
                        </Nav.Item>

                        </Nav>

                <Col  className="pb-4" >
                    <Tab.Content >
                        <Tab.Pane eventKey="first">

                                <div className="container">
                                    <div className="column columns is-multiline ProductsContainer">
                                    {products && products.length ? (
                                        products.filter(products => products.type === "Section1").map((product, index) => (
                                        <ProductItem
                                            product={product}
                                            key={index}
                                            addToCart={this.props.context.addToCart}
                                            user={user}
                                        >
                                        </ProductItem>
                                        ))
                                    ) : (
                                        <div className="column">
                                        <span className="title has-text-grey-light">
                                            No products found!
                                        </span>
                                        </div>
                                    )}
                                    </div>
                                </div>

                                
                        </Tab.Pane>
                        
                        <Tab.Pane eventKey="second">
                            <Container>
                                
                            <div className="container">
                                    <div className="column columns is-multiline ProductsContainer">
                                    {products && products.length ? (
                                        products.filter(products => products.type === "Section2").map((product, index) => (
                                        <ProductItem
                                            product={product}
                                            key={index}
                                            addToCart={this.props.context.addToCart}
                                            user={user}
                                        >
                                        </ProductItem>
                                        ))
                                    ) : (
                                        <div className="column">
                                        <span className="title has-text-grey-light">
                                            No products found!
                                        </span>
                                        </div>
                                    )}
                                    </div>
                                </div>
                            </Container>
                        </Tab.Pane> 

                        <Tab.Pane eventKey="three">
                            <Container>
                                <div className="container">
                                    <div className="column columns is-multiline ProductsContainer">
                                        {products && products.length ? (
                                            products.filter(products => products.type === "Section3").map((product, index) => (
                                            <ProductItem
                                                product={product}
                                                key={index}
                                                addToCart={this.props.context.addToCart}
                                                user={user}
                                            >
                                            </ProductItem>
                                            ))
                                        ) : (
                                            <div className="column">
                                            <span className="title has-text-grey-light">
                                                No products found!
                                            </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Container>
                        </Tab.Pane>
                        
                         
                    </Tab.Content>
                </Col>
        </Tab.Container>
    
  )
}
}
export default withContext(Shop);