import Carousel from 'react-bootstrap/Carousel';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import React from 'react';
import { Link } from 'react-router-dom'

import withContext from "../../withContext"; 


class Header extends React.Component{
    
    importAll(r) {
        let images = {};
        r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
      }
      
    images = this.importAll(require.context('./images', false, /\.(png|jpe?g|svg|webp)$/));
    imagenames = Object.keys(this.images)

    imagesMobile = this.importAll(require.context('./imagesMobile', false, /\.(png|jpe?g|svg|webp)$/));
    imagenamesMobile = Object.keys(this.imagesMobile)
    
    isPortrait() {
        return window.innerHeight > window.innerWidth;
    }

    render (){

        const { cart, freezed } = this.props.context;
        let interval;

        if (freezed === true){
            interval = 500000;

        } else {
            interval = 5000;
        }

        return (
            <>

            <Navbar expand="lg" className="flex-column p-0" paused="no" id="Header">
           <h1 className="Yekaterina"> <Link to="/">Yekaterina Zhmud</Link></h1>
           {this.isPortrait() === false || window.screen.width >= 769 ? 

            <Carousel  fade={true} controls={false} indicators={false} interval={interval} id="HeaderCarousel" >
                {this.imagenames.map(image=>{

                    return (
                        <Carousel.Item className="CarouselItem">
                            <img 
                            className="CarouselItem"
                            src={this.images[image].default}
                            alt="First slide"
                            key={this.images[image]}
                            />
                        </Carousel.Item>
                    )
                })}

            </Carousel> 
            : 
            
            <Carousel  fade={true} controls={false} indicators={false} interval={interval} id="HeaderCarousel" >
            {this.imagenamesMobile.map(image=>{

                return (
                    <Carousel.Item className="CarouselItem">
                        <img 
                        className="CarouselItem"
                        src={this.imagesMobile[image].default}
                        alt="First slide"
                        key={this.imagesMobile[image]}
                        />
                    </Carousel.Item>
                )
            })}

            </Carousel> 

            }
            </Navbar>

            <Navbar expand="md" className="flex-column p-0 mt-2" id="SecondHeader" sticky="top" collapseOnSelect="true">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                <Navbar.Collapse className="w-100 bg-white text-uppercase " justify="true" id="responsive-navbar-nav">

                <Nav
                className="stickynavbar" 
                activeKey="/"
                onSelect={(selectedKey) => {
                let links=['link1', 'link2','link3','link4','link5'];
                links.slice(selectedKey);
                links.forEach(element => {
                    document.getElementById(element).style.color = 'black';
                });
                document.getElementById(selectedKey).style.color = '#29AAE2';

                }}
                justify="true" className="w-100 text-uppercase" 
                >

                <Nav.Item >
                    <Nav.Link className="MainLinksHeader" eventKey="link1"><Link  to="/" id="link1" className="nav-link shineactive "><h4>Home</h4></Link></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className="MainLinksHeader" eventKey="link2"><Link to="/Shop" id="link2" className="nav-link shineactive"><h4>Shop</h4></Link></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className="MainLinksHeader" eventKey="link3"><Link to="/Gallery" id="link3" className="nav-link shineactive"><h4>Gallery</h4></Link></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className="MainLinksHeader" eventKey="link4"><Link to="/ContactForm" id="link4" className="nav-link shineactive"><h4>Contact</h4></Link></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className="MainLinksHeader" eventKey="link5"><Link to="/Cart" id="link5" className="nav-link shineactive"><h4>Cart {Object.keys(cart).length >= 1 ? (<span className="badge badge-pill badge-outline-primary"><span>{ Object.keys(cart).length }</span></span>) : ('')}</h4></Link></Nav.Link>
                </Nav.Item>
                </Nav>
                </Navbar.Collapse>
            </Navbar>

</>
        )
    }}
  
export default withContext(Header);
