import React, {useState, Component} from 'react'
import withContext from "../withContext";
import CartItem from "./CartItem";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { useHistory } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import { loadStripe } from "@stripe/stripe-js";
import Formsincheckout from './formsincheckout';
import Container from 'react-bootstrap/esm/Container';

// const stripePromise = loadStripe("pk_test_51I8WwqJqTsrHDNL1xD4wC6hJfGeFHN9fTwpC9e40m84ojem1aLpQcGw7s4zmLd2qMsFvCXXV415KBlTdVdjYrKvK00Yjv406Gs");
const stripePromise = loadStripe("pk_live_51I8WwqJqTsrHDNL1zj5lZewfGD96BzWgpoHvM1l2Rf1Jt8SNVvtkqyvi9ZeuUWhEUjyrlcuRAVN0OEnHXrCP7iJO00gtaUuL2O");


const EUcountries = [
"Albania",
"Andorra",
"Austria",
"Belarus",
"Belgium",
"Bosnia and Herzegovina",
"Bulgaria",
"Croatia",
"Cyprus",
"Czech Republic",
"Denmark",
"Estonia",
"Faroe Islands",
"Finland",
"France",
"Germany",
"Greece",
"Greenland",
"Hungary",
"Iceland",
"Ireland",
"Italy",
"Kosovo",
"Latvia",
"Liechtenstein",
"Lithuania",
"Luxembourg",
"Macedonia",
"Malta",
"Moldavia",
"Montenegro",
"Norway",
"Poland",
"Romania",
"Portugal",
"San Marino",
"Serbia",
"Slovakia",
"Slovenia",
"Spain",
"Sweden",
"Switzerland",
"Turkey",
"Ukraine",
"United Kingdom",
"Holy See (Vatican City)"]

function ex17(nr){

    const sum = nr.reduce((total, amount) => +total + +amount);
    return sum;
    
    }

class FinalSummary extends React.Component {
  
  componentDidMount() {
    window.scrollTo(0, 250)
  }
  
  render(){
    let finalcart = this.props.context.cart;
    let buyer = this.props.context.buyer;
    let promocode = this.props.context.promocode;
    // let clean = this.props.context.clearCart;
    let clearpromo = this.props.context.clearPromocode;
    let AmountCount = this.props.context.AmountCount;

    async function handleclick (event){
      // clean(); - it's better no to clean right now, but after success I guess
      // AmountCount();
      clearpromo(1000);

      const stripe = await stripePromise;
      const response = await fetch("/create-checkout-session",
        {method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ finalcart, buyer, promocode }) }
        )
      
      const session = await response.json();
      // When the customer clicks on the button, redirect them to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      } else {
      }
    };



    let country = this.props.context.buyer.country;
    let cart = this.props.context.cart;
    let cartKeys = Object.keys(cart || {});
    let cartValues = Object.values(cart || {});
    let summaryprice = 0;
    

  return (
    <>
        <Container className="column columns is-multiline mt-3">
        <div className="hero-body container m-0 p-0">
          <h4 className="title">Final summary</h4>
        </div>
        {cartKeys.length ? (
            <div className="mb-3 ">
            {cartKeys.map(key => (
              <div className="BorderTopCart">

                <CartItem
                  cartKey={key}
                  key={key}
                  cartItem={cart[key]}
                />
              </div>
            ))}
            {function (){
                let length = cartValues.length;
                let alldeliveryprices = [];
                cartValues.forEach(el =>{
                    if (country === 'Netherlands'){
                        alldeliveryprices.push(el.product.priceNL);
                    } else if (EUcountries.includes(country) === true){
                        alldeliveryprices.push(el.product.priceEU);
                    } else {
                        alldeliveryprices.push(el.product.priceWORLD);
                    }

                    if (promocode.discount){
                      if (promocode.ProductID){
                        if (promocode.ProductID.includes(el.product.id)){
                          summaryprice = +summaryprice + +el.product.price*promocode.discount;
                        } else {
                          summaryprice = +summaryprice + +el.product.price;
                        }
                      } else {
                        summaryprice = +summaryprice + +el.product.price*promocode.discount;
                      }
                    } else {
                      summaryprice = +summaryprice + +el.product.price;  
                    }

                });
                let FullDeliveryPrice = Math.max(...alldeliveryprices);
                let RestOfPrices = +ex17(alldeliveryprices) - +FullDeliveryPrice;
                let RawTotalPirce = summaryprice;

                summaryprice = +summaryprice + +FullDeliveryPrice + +RestOfPrices/2;
                
                return (
                  <>

                    <Row className="pt-3 BorderTopCart">
                      <Col> 
                        <h4> Price: </h4>
                      </Col>
                      
                      <Col className="ItemCartPrice">
                        <h4> {RawTotalPirce}€</h4>
                      </Col>

                    </Row>


                    <Row className="">
                      <Col> 
                        <h4>Delivery to {country}: </h4>
                      </Col>
                      
                      <Col className="ItemCartPrice">
                        <h4> {ex17(alldeliveryprices)}€</h4>
                      </Col>

                    </Row>

                    {length > 1 ? (
                     <Row className="">
                     <Col> 
                       <h4>Discount:  </h4>
                     </Col>
                     
                     <Col className="ItemCartPrice">
                       <h4> {Math.round(summaryprice) - ex17(alldeliveryprices) - RawTotalPirce}€</h4>
                     </Col>
                   </Row>
                     ) : ("")}
                    
                    <Row className="pt-2 ">
                      <Col className="ItemCartPrice ">
                        <h4 className="BorderTopCart"> Total price:   {Math.round(summaryprice)}€</h4>
                      </Col>
                    </Row>
                    
                    <Row className="pt-5">
                      <Col> 
                        <h5>We will ship your purchase to: {buyer.country}, {buyer.city}, {buyer.street}, {buyer.houseN}, {buyer.index}</h5>
                      </Col>
                    </Row>

                  </>
                    )


                
            }()}
            <br/>
            <Button variant="outline-secondary" onClick={() => window.history.back()}>Back</Button>

            <Button
            variant="outline-success"
            className="button is-success ml-2"
            onClick={(e)=>handleclick(e)}
            >
            Proceed to payment
            </Button>
            </div>
        ) : (<div><p>You will be redirected soon...</p></div>)}
                  
        </Container>
            

    </>
  );
  }
};

export default withContext(FinalSummary);