import React from 'react';
import Mains from '../src/mains/mains'
import Container from 'react-bootstrap/Container';

import './App.css';


const App = () => (
  <Container id="MainContainer">
    <Mains key={window.location.href}/>
  </Container>
);

export default App;
