import React, { Component } from "react";
import withContext from "../withContext";
import { Redirect } from "react-router-dom";
import axios from 'axios';

const initState = {
  name: "",
  price: "",
  priceNL: "",
  priceEU: "",
  priceWORLD: "",
  stock: 0,
  shortDesc: "",
  description: "",
  URLp: "",
  URLp2: "",
  URLp3: "",
  URLp4: "",
  URLp5: "",
  type: "Section1",
  order: ""
};

class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }
  save = async (e) => {
    e.preventDefault();
    const { name, price, priceNL, priceEU, priceWORLD, stock, shortDesc, description, URLp, URLp2, URLp3, URLp4, URLp5, type, order } = this.state;

    if (name && price) {
      const id = Math.random().toString(36).substring(2) + Date.now().toString(36);

      await axios.post(
        '/addproduct',
        { id, name, price, priceNL, priceEU, priceWORLD, stock, shortDesc, description, URLp, URLp2, URLp3, URLp4, URLp5, type, order },
      )
      this.props.context.addProduct(
        {
          name,
          price,
          priceNL, 
          priceEU, 
          priceWORLD,
          shortDesc,
          description,
          stock: stock || 0,
          URLp, URLp2, URLp3, URLp4, URLp5,
          type,
          order
        },
        () => this.setState(initState)
      );
      this.setState(
        { flash: { status: 'is-success', msg: 'Product created successfully' }}
      );

    } else {
      this.setState(
        { flash: { status: 'is-danger', msg: 'Please enter name and price' }}
      );
    }
  };
  handleChange = e => this.setState({ [e.target.name]: e.target.value, error: "" });
  render() {
    const { name, price, priceNL, priceEU, priceWORLD, stock, shortDesc, description, URLp, URLp2, URLp3, URLp4, URLp5, type, order } = this.state;
    const { user } = this.props.context;


    return !(user && user.accessLevel < 1) ? (
      <Redirect to="/" />
    ) : (
      <>
        <div className="hero is-primary ">
          <div className="hero-body container">
            <h4 className="title">Add Product</h4>
          </div>
        </div>
        <br />
        <br />
        <form onSubmit={this.save}>
          <div className="columns is-mobile is-centered">
            <div className="column is-one-third">
              <div className="field">
                <label className="label">Product Name: </label>
                <input
                  className="input"
                  type="text"
                  name="name"
                  value={name}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="field">
                <label className="label">Price: </label>
                <input
                  className="input"
                  type="number"
                  name="price"
                  value={price}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="field">
                <label className="label">priceNL: </label>
                <input
                  className="input"
                  type="number"
                  name="priceNL"
                  value={priceNL}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="field">
                <label className="label">priceEU: </label>
                <input
                  className="input"
                  type="number"
                  name="priceEU"
                  value={priceEU}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="field">
                <label className="label">priceWORLD: </label>
                <input
                  className="input"
                  type="number"
                  name="priceWORLD"
                  value={priceWORLD}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="field">
                <label className="label">Available in Stock: </label>
                <input
                  className="input"
                  type="number"
                  name="stock"
                  value={stock}
                  onChange={this.handleChange}
                />
              </div>
              <div className="field">
                <label className="label">Short Description: </label>
                <input
                  className="input"
                  type="text"
                  name="shortDesc"
                  value={shortDesc}
                  onChange={this.handleChange}
                />
              </div>
              <div className="field">
                <label className="label">Description: </label>
                <textarea
                  className="textarea"
                  type="text"
                  rows="2"
                  style={{ resize: "none" }}
                  name="description"
                  value={description}
                  onChange={this.handleChange}
                />
              </div>
              <div className="field">
                <label className="label">URLp: </label>
                <input
                  className="input"
                  type="url"
                  name="URLp"
                  value={URLp}
                  onChange={this.handleChange}
                />
              </div>
              <div className="field">
                <label className="label">URLp2: </label>
                <input
                  className="input"
                  type="url"
                  name="URLp2"
                  value={URLp2}
                  onChange={this.handleChange}
                />
              </div>
              <div className="field">
                <label className="label">URLp3: </label>
                <input
                  className="input"
                  type="url"
                  name="URLp3"
                  value={URLp3}
                  onChange={this.handleChange}
                />
              </div>
              <div className="field">
                <label className="label">URLp4: </label>
                <input
                  className="input"
                  type="url"
                  name="URLp4"
                  value={URLp4}
                  onChange={this.handleChange}
                />
              </div>
              <div className="field">
                <label className="label">URLp5: </label>
                <input
                  className="input"
                  type="url"
                  name="URLp5"
                  value={URLp5}
                  onChange={this.handleChange}
                />
              </div>
              <div className="field">
                <label className="label">Type: </label>
                <select name="type" onChange={this.handleChange}>
                    <option selected="selected" value="Section1">Section1</option>
                    <option value="Section2">Section2</option>
                    <option value="Section3">Section3</option>
                </select>
                
              </div>
              <div className="field">
                <label className="label">Order: </label>
                <input
                  className="input"
                  type="number"
                  name="order"
                  value={order}
                  onChange={this.handleChange}
                />
              </div>
              {this.state.flash && (
                <div className={`notification ${this.state.flash.status}`}>
                  {this.state.flash.msg}
                </div>
              )}
              <div className="field is-clearfix">
                <button
                  className="button is-primary is-outlined is-pulled-right"
                  type="submit"
                  onClick={this.save}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default withContext(AddProduct);