import React from "react";
import withContext from "../withContext";
import CartItem from "./CartItem";
import Form from 'react-bootstrap/Form';

import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const CartComponent = props => {
  const { cart, removeFromCart, promocode, CheckPromocode, clearCart} = Object.values(props)[0];
  const cartKeys = Object.keys(cart || {});
  const cartValues = Object.values(cart || {});
  let summaryprice = 0;
  let summarypriceEU = 0;

  function handlechange(e){
    e.preventDefault();
    CheckPromocode(e.target[0].value);
  }

  cartValues.forEach(key => {
    if (promocode.name){
      if (promocode.ProductID){
        if (promocode.ProductID.includes(key.product.id)){
          summaryprice = +summaryprice + +key.product.price*promocode.discount;
        } else {
          summaryprice = +summaryprice + +key.product.price;
        }
      } else {
        summaryprice = +summaryprice + +key.product.price*promocode.discount;
      }
    } else {
      summaryprice = +summaryprice + +key.product.price;  
    }
  })

  cartValues.forEach(key => {
    summarypriceEU = +summarypriceEU + +key.product.priceNL;
    })

  return (
    
    <>
      <div className="hero is-primary">
        <div className="hero-body container">
          <h4 className="title">My Cart</h4>
        </div>
      </div>
      <div className="container">
        {cartKeys.length ? (
          <div className="column columns is-multiline BorderTopCart">
            {cartKeys.map(key => (
              <div className="BorderBottomItemCart CartItemContainer">
              <CartItem
                cartKey={key}
                key={key}
                cartItem={cart[key]}
                removeFromCart={removeFromCart}
              />

              </div>
            ))}
            <div className="column is-12 is-clearfix">
              <br />
              <div className="is-pulled-right pb-4">

              <div className="mb-4">

                <span className="totalCost"> TOTAL COSTS: {summaryprice}€</span>

                <Form onSubmit={handlechange}>
                  <label className="label">Do you have a promocode?</label>
                  <input
                    className="input ml-3 p-1"
                    type="text"
                    name="promocode"
                  />
                {promocode.discount ? ( <Button className="ml-3 align-baseline" type="submit" variant="success"> your promocode is valid!</Button> ) 
                 : ( promocode.message ? (<Button className="ml-3 align-baseline" type="submit" variant="outline-danger"> {promocode.message} :'(</Button>) 
                 : (<Button className="ml-3 align-baseline" type="submit" variant="outline-secondary"> check the promocode</Button>) )
                }
                </Form>

                  <span> SHIPPING COST: FROM {""}
                  <OverlayTrigger
                    key="right"
                    placement="right"
                    overlay={
                      <Tooltip id={`tooltip-right`}>
                        Shipping costs are calculated at the checkout
                      </Tooltip>
                    }
                  >
                    <Button variant="m-0 p-0 DeliveryPriceTooltip"> €{Math.round(summarypriceEU)}* </Button>
                  </OverlayTrigger>
                </span>
                <span><br></br>All pieces sold by Yekaterina Zhmud are carefully and nicely packed in paper and bubble wrap, delivered in a cardboard box filled by air bags. The lot is sent within 3 days after the payment, with track and trace.
                </span>


              </div>

                <Button
                  onClick={clearCart}
                  className="button is-warning "
                  variant="outline-secondary"
                >
                  Clear cart
                </Button>{" "}


                <Link className=""
                to={{pathname:"/Precheckout" }}> 
                                <Button
                  className="button is-success" variant="outline-success"
                >Proceed to delivery details</Button>
                </Link>              

              </div>
            </div>
          </div>
        ) : (
          <div className="pt-2">
            <h3 className=" BorderTopCart pt-2 ">The cart is empty</h3>
          </div>
        )}
      </div>
    </>
  );
};

class Cart extends React.Component {
  componentDidMount(){
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
  });
    this.props.context.refresh();
  }
  render(){
    return (
      <CartComponent props={this.props.context}/>
    )
  }
}


export default withContext(Cart);