import React, { Component } from "react";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Figure from 'react-bootstrap/Figure';
import Carousel from 'react-bootstrap/Carousel';
import withContext from "../../withContext"; 
import Button from 'react-bootstrap/Button';
import outofstock from './sold_out.png';
import cross from './close.png';
import { Link } from 'react-router-dom';
import maestro from './payLogos/maestro.png';
import mastercard from './payLogos/mastercard.png';
import visa from './payLogos/visa_electron.png';
import ideal from './payLogos/ideal-logo-1024.png';
import stripe from './payLogos/stripe.png';





class Item extends React.Component {
  

  addToCart = this.props.context.addToCart;
  refresh = this.props.context.refresh();

  componentDidMount() {
    this.props.context.refresh();
    window.scrollTo(0, 350);

  }

  
  render (){
    let count = 1;

    function handleclick(){
      document.getElementById('CarouselToAppend').classList.add("GalleryBackground");
      document.getElementsByClassName('ShopItemCarousel')[0].style.width = '100%';
      document.getElementsByClassName('ShopItemCarousel')[0].style.height = '100%';
      let elements = document.getElementsByClassName('ShopItem');
      for (var i=0; i<elements.length; i++) {
      document.getElementsByClassName('ShopItemCarouselToAppend')[i].classList.add("glryimg");
      document.getElementsByClassName('ShopItemCarouselToAppend')[i].classList.remove("img-fluid");
      document.getElementsByClassName('CancelcrossShop')[i].style.width = '2em';
      document.getElementsByClassName('CancelcrossShop')[i].style.height = '2em';
      document.getElementsByClassName('MainShopItemCrossLink')[i].style.width = '2em';
      document.getElementsByClassName('MainShopItemCrossLink')[i].style.height = '2em';
      let style = elements[i].style
      let widts = elements[i].style.width;
      let heights = elements[i].style.height;
      console.log(style);
      console.log(widts);
      console.log(heights);
      elements[i].style.width = '100%';
      elements[i].style.height = '100%';
      }
      count = 0;
    }


    function handlecrossclick(){
      document.getElementById('CarouselToAppend').classList.remove("GalleryBackground");
      document.getElementsByClassName('ShopItemCarousel')[0].style.width = 'none';
      document.getElementsByClassName('ShopItemCarousel')[0].style.height = 'none';
      let elements = document.getElementsByClassName('ShopItem');
      for (var i=0; i<elements.length; i++) {
        document.getElementsByClassName('ShopItemCarouselToAppend')[i].classList.remove("glryimg");
        document.getElementsByClassName('ShopItemCarouselToAppend')[i].classList.add("img-fluid");
        document.getElementsByClassName('CancelcrossShop')[i].style.width = '0em';
        document.getElementsByClassName('CancelcrossShop')[i].style.height = '0em';
        document.getElementsByClassName('MainShopItemCrossLink')[i].style.width = '0em';
        document.getElementsByClassName('MainShopItemCrossLink')[i].style.height = '0em';
        if (window.screen.width >= 769){
          elements[i].style.width = '560px';
          elements[i].style.height = '560px';    
          } else {
          elements[i].style.width = '360px';
          elements[i].style.height = '360px';   
          }   
        }
        count = 1;
  
    }

    const { products, cart } = this.props.context;
    let currentprops = this.props.location.aboutProps;

    if (currentprops !== undefined){
      // console.log(`currentprops is defined, proceed...; ${JSON.stringify(currentprops)}`);
      // localStorage.setItem("lastusedprops", JSON.stringify(currentprops));

    } else {
      // console.log(`currentprops is undefined, proceed...`);
      products.forEach(product => {
        let web = window.location.href;
        let index = web.indexOf(product.id);

        if (index !== -1){
          // console.log('index!==-1?')
          currentprops = ({"product": product });
          // console.log(`im assigning currentprops after retrieve data from URL, currentprops is ${JSON.stringify(currentprops)}`);
        }
      })

       // currentprops = JSON.parse(localStorage.getItem("lastusedprops"));
      // console.log(`im calling currentprops after retrieve data from localstorage, currentprops is ${currentprops}`);
        
      if (typeof currentprops === undefined){
          return (
            <div className="column">
            <span className="title has-text-grey-light">
                No products found :( Wait for a bit please
            </span>
            </div>
          )
      }
    }
    // console.log(currentprops.product.id)
    function ousidecart(){
      if (cart){
        var values = [];
        Object.values(cart).forEach(value => values.push(value.product.id))
        if (values.includes(currentprops.product.id)){
          return true
        }
      }
    };
    ;
  

    return (
      <>
      {products&&products.length>1 ? (
      <Container>
        <Row className="ItemMainContainer mt-5">
          <Col xs className="MainShopItemCarouselContainer">
            
            <Carousel controls={true} indicators={true} interval={null} className="ShopItemCarousel" id="CarouselToAppend" >

              <Carousel.Item className="ShopItem" >
                <div id="CarouselItemToAppend" className="glry">
                <div className="CancelcrossShop" onClick={handlecrossclick}>
                 <img src={cross} className="MainShopItemCrossLink"></img>
                   
                  </div>
                <Figure.Image className="ShopItemCarouselToAppend" onClick={handleclick}
                  src={currentprops.product.URLp} alt="something goes wrong :("
                />

                </div>

              </Carousel.Item>
                   
              {currentprops.product.URLp2 && currentprops.product.URLp2.length > 5 && (
                
              <Carousel.Item className="ShopItem" >
                <div id="CarouselItemToAppend" className="glry">
                <div className="CancelcrossShop" onClick={handlecrossclick}>
                  <img src={cross} className="MainShopItemCrossLink"></img>
                
                  </div>
              <Figure.Image className="ShopItemCarouselToAppend" onClick={handleclick}
                    src={currentprops.product.URLp2} alt="something goes wrong :("
                  />

                 </div>

                </Carousel.Item>
                )}
                  
              {currentprops.product.URLp3 && currentprops.product.URLp3.length > 5 && (
              <Carousel.Item className="ShopItem" >
              <div id="CarouselItemToAppend" className="glry">
              <div className="CancelcrossShop" onClick={handlecrossclick}>
              <img src={cross} className="MainShopItemCrossLink"></img>
                  
                  </div>
              <Figure.Image className="ShopItemCarouselToAppend" onClick={handleclick}

                    src={currentprops.product.URLp3} alt="something goes wrong :("
                  />

              </div>
                </Carousel.Item>
                )}

              {currentprops.product.URLp4 && currentprops.product.URLp4.length > 5 && (
              <Carousel.Item className="ShopItem" >
              <div id="CarouselItemToAppend" className="glry">
              <div className="CancelcrossShop" onClick={handlecrossclick}>
                  <img src={cross} className="MainShopItemCrossLink"></img>
                
                  </div>
              <Figure.Image className="ShopItemCarouselToAppend" onClick={handleclick}
 
                    src={currentprops.product.URLp4} alt="something goes wrong :("
                  />

              </div>
                </Carousel.Item>
              )}
                
              {currentprops.product.URLp5 && currentprops.product.URLp5.length > 5 && (
              <Carousel.Item className="ShopItem" >
              <div id="CarouselItemToAppend" className="glry">
              <div className="CancelcrossShop" onClick={handlecrossclick}>
             <img src={cross} className="MainShopItemCrossLink"></img>
                
                  </div>
              <Figure.Image className="ShopItemCarouselToAppend" onClick={handleclick}

                    src={currentprops.product.URLp5} alt="something goes wrong :("
                  />

                </div>
                </Carousel.Item>
              )}          
            </Carousel>

          </Col>
      
          <Col xs className="MainItemDescriptionContainer">
            <Row>
              <h1>{currentprops.product.name}</h1>
            </Row>
            <Row>
              <h5 className="mb-3">{currentprops.product.shortDesc}</h5>          
            </Row>
            <Row>
              <h5 className="mb-4">{currentprops.product.description}</h5>          
            </Row>
            <Row>
              <h3 className="mb-4">{currentprops.product.price}€</h3>
            </Row>
            <Row>
              <div className="itemShippingCosts">
                <h4 className=''>Shipping costs (calculated at the checkout):<br/></h4>
                <h4>Netherlands: {currentprops.product.priceNL == 0 ? 'free' : `${currentprops.product.priceNL}€`}; Europe: {currentprops.product.priceEU}€; World: {currentprops.product.priceWORLD}€</h4>
                <h4></h4>
                <h4 className="mb-1"></h4>
              </div>       
            </Row>

            <Row>
            {currentprops.product.stock <= 0 && (
              <Row>
                <img className ="OutOfStockImg ml-3" src={outofstock} alt='sorry, this painting is out of stock :('/>          
              </Row> 
            )}
            {currentprops.product.stock >= 1 && (
              <Button
              variant={ousidecart() ? ("success") : ("info")}
              className="button is-small is-outlined is-primary is-pulled-right AddItemToCartButton mt-3 mb-3"
              onClick={() => {
                this.addToCart({
                  id: currentprops.product.name,
                  product: currentprops.product,
                  amount: 1
                })}
              }
            >
              {ousidecart() ? ("ADDED TO CART") : ("ADD TO CART")}</Button>
            )}

            </Row>
            <Row className="itemShippingCosts mb-0">            <h4 >Safe checkout with                 <img className="icon " src={stripe} alt="picture"/></h4>          
              </Row>
            <Row className="bubbleWrap">

            <div className="">
                <img className="icon " src={maestro} alt="picture"/>
                <img className="icon " src={visa} alt="picture"/>
                <img className="icon " src={mastercard} alt="picture"/>
                <img className="icon " src={ideal} alt="picture"/>

            </div>
            <h5><em><small>All pieces sold by Yekaterina Zhmud are carefully and nicely packed in paper and bubble wrap, delivered in a cardboard box filled by air bags. The lot is sent within 3 days after the payment, with track and trace.</small></em></h5>          

            </Row>
          </Col>

        </Row>
      </Container>) :
    (<div className="column">
    <span className="title has-text-grey-light">
      No products found :( Wait for a bit please
    </span>
    </div>) }
    </>
    )
  }
}

export default withContext(Item);
