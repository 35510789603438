import React from "react";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

import trash from './trash.png'
import Col from "react-bootstrap/esm/Col";


const CartItem = props => {
    const { cartItem, removeFromCart, cartKey } = props;
    let web = window.location.href;
    let index = web.indexOf('Cart');

    const { product } = cartItem;
    return (
        <Row className="CartItemContainer pt-5 pb-5">
          
          <Col className="box innerimg2">
            <img className="ShopItemImage innerimg2"
              src={product.URLp}
              alt={product.shortDesc}
            />
          </Col>
              
          <Col className="ItemCartDescription">
            <h3 style={{ textTransform: "capitalize" }}>
              "{product.name}"{" "}
            </h3> 
            <h5>{product.shortDesc}</h5>
          </Col>
          
          <Col className="ItemCartPriceContainer">              
            <h4 className="ItemCartPrice tag is-primary">{product.price}€</h4>
            {index !== -1 ?
            <div className ="RemoveFromCartButtonContainer">
                <Button className="RemoveFromCartButton" onClick={() => removeFromCart(cartKey)}> 
                  <Image className="RemoveFromCartButtonImage mr-1" fluid src={trash}></Image>
                  Remove from the cart
                </Button>
            </div> : ""}

          </Col>

          </Row>
     
      );
    };
    
    export default CartItem;