import React, {useState, Component} from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import formik from 'react-bootstrap/FormControl';
import { yup, object, string } from 'yup';
import ContactKate from './ContactKate.jpg'
import axios from 'axios';
import PrivacyPolicy from './Privacy Policy for yekaterinazhmud.pdf'


import withContext from "../../withContext";

import Row from 'react-bootstrap/Row';

import { Link } from 'react-router-dom'

class ContactForm extends React.Component {
  componentDidMount() {
    this.props.context.refresh();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
  });
  }
  render (){

    function FormExample() {
      const [validated, setValidated] = useState(false);
    
      const handleSubmit = (event) => {

        const form = event.currentTarget;
        // console.log(form[0].value);
        // console.log(form[1].value);
        // console.log(form[2].value);
        // console.log(form[3].value);
        let contactinfo = [form[0].value,form[1].value,form[2].value,form[3].value];


        if (form.checkValidity() === false) {

          event.preventDefault();
          event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {

        axios.post('/newformarrived', {
          method: 'post',
          data: contactinfo,
          headers: {'Content-Type': 'multipart/form-data' }
          })
          .then(function (response) {
              //handle success

          })
          .catch(function (response) {
              //handle error
          });
      }
    };
    
      return (

        <Row className="m-4 justify-content-md-center " >
          <Col >
            <Form noValidate validated={validated} onSubmit={handleSubmit} >
              
                <p className="text-left">
                    <h5>If you have any questions about my website or my artworks, please feel free to contact me. I’d love to hear from you! If you want to order a print but didn’t see it on this website - contact me for availability or custom order. <br/>
                    <Form.Label className="mt-1">I appreciate all kinds of feedback! Thanks for getting in touch, Kate</Form.Label>
                    </h5>
                </p>
              
              <Form.Row className="mt-2">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Name"
                  />
                </Form.Group>
              </Form.Row>

              
              <Form.Row>
                <Form.Group as={Col} md="6" controlId="validationCustom02">
                  <Form.Control required type="email" placeholder="Email" />
                </Form.Group>
              </Form.Row>
                  
              <Form.Row>
                <Form.Group as={Col} md="6" controlId="validationCustom02">
                  <Form.Control
                    required
                    type="text"
                    placeholder="What's your topic?"
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="12" controlId="validationCustom03">
                  <InputGroup>
                    <FormControl as="textarea" required placeholder="Type your message here" />
                  </InputGroup>
                </Form.Group>
              </Form.Row>

              <Form.Group>
                <Form.Check  type='checkbox' id={`id`}>
                  <Form.Check.Input required type='checkbox' />
                  <Form.Check.Label>{<a href={PrivacyPolicy} target="_blank">Agreed with the privacy policy</a>}</Form.Check.Label>
                  <Form.Control.Feedback type="invalid">You shoud agree with the privacy policy</Form.Control.Feedback>                 
                </Form.Check>
              </Form.Group>
              
              <Button variant="outline-success" type="submit">Submit form</Button>
             
            </Form>
          </Col>
         
          <Col className="text-center" >
              <img
                  className="w-75"
                  src={ContactKate}
                  alt="cat"
              />
          </Col>
        </Row>

      )
    }

   return <FormExample />
  
}}

export default withContext(ContactForm)
