import React, { useLayoutEffect, useRef, useState } from "react";
import withContext from "../../withContext";
import Button from 'react-bootstrap/Button';
import Footer from '../footer/footer'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import DownArrow from '../pages/arrow-down-sign-to-navigate.png'
import UpArrow from '../pages/navigate-up-arrow.png'

const Boxes = (props) => {
  const { cart, addToCart } = Object.values(props)[0];
  console.log(cart)

    const [show, doShow] = useState({
      itemOne: false,
      itemTwo: false,
      itemThree: false,
      showVideo: false
    });

    const ourRef = useRef(null),
      anotherRef = useRef(null),
      refThree = useRef(null),
      refFour = useRef(null)
  
    useLayoutEffect(() => {
      const topPos = element => element.getBoundingClientRect().top;
      const div1Pos = topPos(ourRef.current),
        div2Pos = topPos(anotherRef.current),
        div3Pos = topPos(refThree.current),
        div4Pos = topPos(refFour.current);

  
      const onScroll = () => {
        const scrollPos = window.scrollY + window.innerHeight/2;

        if (div1Pos < scrollPos) {
          doShow(state => ({ ...state, itemOne: true }));
        } 
        if (div2Pos < scrollPos) {
          doShow(state => ({ ...state, itemTwo: true }));
        } 
        if (div3Pos < scrollPos) {
          doShow(state => ({ ...state, itemThree: true }));
        }
        if (div4Pos < scrollPos) {
            doShow(state => ({ ...state, itemFour: true }));
          }
      };
  
      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }, []); 
    function handleclick(){
        doShow(state => ({ ...state, showVideo: true }));
    }

    let CurrentArrow = DownArrow;

    function ShowMore(){
      console.log('handleclicking..')
      let dots = document.getElementById("dots");
      let moreText = document.getElementById("more");
      let arrow = document.getElementById("myArrow");
    
      if (dots.style.display === "none") {
        arrow.style.transform = "rotate(0deg)"
        dots.style.display = "inline-block";
        moreText.style.display = "none";
        CurrentArrow = UpArrow;
      } else {
        arrow.style.transform = "rotate(180deg)"
        dots.style.display = "none";
        moreText.style.display = "inline-block";
        CurrentArrow = DownArrow;

      }
    }
    return (
      <div className="BoxContainer">

        <div className="BoxWrapper">
            <div ref={ourRef} className="DefaultBoxClass FirstBoxContainer"> 
                <h2 className={`${show.itemOne ? "BoxText" : ""}`}>BOXES ARE HERE, OLD MAN</h2>
            </div>

            <div ref={anotherRef} className="DefaultBoxClass SecondBoxContainer bg-white"> 
                <Col className="BoxCol">
                    <h2 className={`${show.itemTwo ? "FirstBoxFirstTextline" : " HiddenStuff"}`}>Our first box is here...</h2>
                    <h4 className={`ml-1 ${show.itemTwo ? "FirstBoxSecondTextline" : " HiddenStuff"}`}>... and it's sexy as hell</h4>
                    <button className={`ml-1 ${show.itemTwo ? "FirstBoxButton CommonButton" : " HiddenStuff CommonButton"}`}>Add to cart</button>
                    
                    <div className={`ml-1 ${show.itemTwo ? "FirstBoxSecondTextline" : " HiddenStuff"}`}>
                      <div className={`${show.itemTwo ? "BoxDescriptionContainer" : " HiddenStuff"}`}>
                        <h5 className={`mt-5 ${show.itemTwo ? "BoxDescription" : " HiddenStuff"}`}>I made this box with love and fingers. The box consist from feathers and sperm of my husband. My husband is very nice, I like him and his dick <span id="dots">...</span> <span id="more">is fucking huge, I mean what the hell is that size and how it manage to fill my little hole.</span></h5>
                        <img src={CurrentArrow} className={`${show.itemTwo ? "SmallArrow" : " HiddenStuff"}`} id="myArrow" onClick={ShowMore}></img>
                      </div>
                    </div>
                    
                </Col>

                <div className={`${show.itemTwo ? "FirstBoxPhotoContainer" : ""}`}></div>
            </div>
            
            <div ref={refThree} className="DefaultBoxClass ThirdBoxContainer bg-light"> 
                <div className={`${show.itemThree ? "SecondBoxPhotoContainer" : " HiddenStuff"}`}></div>

                <div className="Row">
                    <h2 className={`${show.itemThree ? "FirstBoxFirstTextline" : " HiddenStuff"}`}>This is my second box...</h2>
                    <h4 className={`ml-1 ${show.itemThree ? "FirstBoxSecondTextline" : " HiddenStuff"}`}>... still hot and very sexy</h4>
                    <button className={`ml-1 ${show.itemThree ? "FirstBoxButton CommonButton" : " HiddenStuff CommonButton"}`}
                      onClick={() => {
                        addToCart({
                          id: '8rjsmzrj6t3kkk28xiw',
                          product: {"_id":{"$oid":"601d44b19dc51fc935d75901"},"id":"yrvhxq1knmikkk27x5r","name":"Earrings, lizard edition","price":"220","priceNL":"17","priceEU":"25","priceWORLD":"45","stock":{"$numberInt":"10"},"shortDesc":"40x50cm, acrylic on canvas, 2020","description":"The painting has painted edges and has a layer of varnish that will protect it against dirt and moisture.","URLp":"https://i.imgur.com/iEBNOyM.jpg","URLp2":"https://i.imgur.com/FHBvIWX.jpg","URLp3":"https://i.imgur.com/LIee0TE.jpg","URLp4":"https://i.imgur.com/SGMuF26.jpg","URLp5":"https://i.imgur.com/ZGNDsuC.jpg","type":"Originals"},
                          amount: 1
                        })}
                      }>Add to cart</button>
                </div>

            </div>
            
            <div ref={refFour} className="DefaultBoxClass FourthBoxContainer bg-dark"> 
                <h2 className={`mt-5 ${show.itemFour ? "BoxText" : " HiddenStuff"}`}>How I made it you ask?</h2>
                <h2 className={`mt-5 ${show.itemFour ? "BoxText" : " HiddenStuff"}`}> Click <a onClick={handleclick} className={`HerePointer ${show.itemThree ? " BoxTextClickHere" : " HiddenStuff"}`}> here</a> and watch the video about it</h2>
                {show.showVideo ? <iframe frameBorder="0" width="420" height="345" src="https://www.youtube.com/embed/tgbNymZ7vqY">
                </iframe> : ""}
            </div>

            <div ref={refFour} className="DefaultBoxClass FourthBoxContainer bg-light"> 
                <Link  to="/" className="ToMainWebsite"><h1>To the main website</h1></Link>
            </div>
        </div>
        <Footer />

      </div>
    );
  };

class Box extends React.Component{
  componentDidMount(){
    this.props.context.refresh();
    console.log(this.props.context.cart);
  }

  render(){
    const {cart} = this.props.context;
    return (
    <div className="BoxesOuterContainer">
    <Navbar expand="md" className={`boxheadernodisplay flex-column p-0 ${Object.keys(cart).length >= 1 ? "boxheader " : ""}`} id="secondheader" sticky="top" collapseOnSelect="true">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                <Navbar.Collapse className="w-100 bg-white text-uppercase " justify="true" id="responsive-navbar-nav">

                <Nav
                className="stickynavbar" 
                activeKey="/"
                onSelect={(selectedKey) => {
                let links=['link1', 'link2','link3','link4','link5'];
                links.slice(selectedKey);
                links.forEach(element => {
                    document.getElementById(element).style.color = 'black';
                });
                document.getElementById(selectedKey).style.color = '#29AAE2';

                }}
                justify="true" className="w-100 text-uppercase" 
                >

                <Nav.Item >
                    <Nav.Link className="MainLinksHeader" eventKey="link1"><Link  to="/" id="link1" className="nav-link shineactive "><h4>Home</h4></Link></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className="MainLinksHeader" eventKey="link2"><Link to="/Shop" id="link2" className="nav-link shineactive"><h4>Shop</h4></Link></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className="MainLinksHeader" eventKey="link3"><Link to="/Gallery" id="link3" className="nav-link shineactive"><h4>Gallery</h4></Link></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className="MainLinksHeader" eventKey="link4"><Link to="/ContactForm" id="link4" className="nav-link shineactive"><h4>Contact</h4></Link></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className="MainLinksHeader" eventKey="link5"><Link to="/Cart" id="link5" className="nav-link shineactive"><h4>Cart {Object.keys(cart).length >= 1 ? (<span className="badge badge-pill badge-outline-primary"><span>{ Object.keys(cart).length }</span></span>) : ('')}</h4></Link></Nav.Link>
                </Nav.Item>
                </Nav>
                </Navbar.Collapse>
            </Navbar>;
            <Boxes props={this.props.context} />

            </div>)
  }
}


export default withContext(Box)
