import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import withContext from "../../withContext"; 


class Gallery extends React.Component{


    importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
      }
      
    images = this.importAll(require.context('./GalleryPhotos', false, /\.(png|jpe?g|svg|jpg|JPG)$/));
    imagevalues = Object.values(this.images);


    componentDidMount(){
        document.body.style.overflow = "visible";
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });  
        this.props.context.refresh();    
    }
     
    
    render(){
    let galleryphotos = [];

    this.imagevalues.forEach(value=>{
        galleryphotos.push(value.default)
    })

        const { freezed } = this.props.context;
        if (freezed === true) {
        this.props.context.setindicator()   
        }
        function handleclick() {
            
            document.body.style.overflow = "hidden";
            document.getElementById('HeaderCarousel').classList.add('add');
            // element.setAttribute("interval", "null");
            // document.getElementById('HeaderCarousel')[1].setAttribute("paused", "yes");

            
    
            function thisfunction(){//NAMING FOR CHRIST SAKE!!!!
                var executed = false;
                window.onhashchange = function() {
                    if (!executed) {
                document.body.style.overflow = "visible";
                executed = true;
                    }
            }}
            setTimeout(thisfunction, 1);
     
            };

        return (
                <Row className=" justify-content-center mt-4 mb-4">
                    {galleryphotos.reverse().map((item)=>{
                        return (
                                <div className="innerimg card-img m-2 card">
                                    <>
                                        <img className="innerimg2 w-100 h-100" src={item} alt="Card image"/>
                                        <Link onClick={handleclick} className="clickasd position-absolute top-0 left-0 w-100 h-100"
                                        to={{pathname:"/GalleryItem", about: {index: galleryphotos.indexOf(item), items: galleryphotos}}}> 
                                        </Link>
                                    </>
                                </div>
                        )
                    })
                    }
                </Row>
        )
    }
}

export default withContext(Gallery);